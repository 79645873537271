import React, { useState } from 'react';
import {
  func, object,
} from 'prop-types';
import _pick from 'lodash.pick';

import {
  Paper, Typography,
} from '@material-ui/core';

import tracker from 'shared/3rdparty/pageTracking';
import logger from 'shared/3rdparty/logger';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';

import {
  consolidateErrors,
  flattenValidation,
} from 'shared/utils/validation';
import TotalColumn from '../TotalColumn';
import SignupWrapper from '../../SignupWrapper';
import AccountInfo from './AccountInfo';
import { totalCardContainer } from '../styles';
import { usePaymentProvider } from '../../PaymentProviderContext';

const AccountSetup = ({
  setData, signup, requestHostedPage,
}) => {
  const instance = usePaymentProvider();
  const [status, setStatus] = useState('initial');

  const onSubmit = async () => {
    setStatus('loading');
    const breadcrumbMeta = _pick(signup, ['firstName', 'lastName', 'companyName', 'billingPlanId', 'addons', 'region', 'email']);

    tracker.push({
      event: 'atomic_signup_payment_started',
    });

    const data = {
      firstName: signup.firstName,
      lastName: signup.lastName,
      email: signup.email,
      password: signup.password,
      companyName: signup.companyName,
      billingPlanId: signup.plan.id,
      region: signup.region.id,
      cycle: signup.cycle,
      billingType: 'chargebee',
    };

    // if we don't have a token yet, tokenize the cb payment data
    logger.client.leaveBreadcrumb('Requested Chargebee token', breadcrumbMeta);
    let newAccount;
    try {
      logger.client.leaveBreadcrumb('Creating Account for new signup', {
        ...breadcrumbMeta,
      });

      setErrors({ general: '' });
      // newAccount = await requestHostedPage(data);

      instance.openCheckout({
        hostedPage: () => {
          return requestHostedPage(data);
        },
        success(hostedPageId) {
          setStatus('success');
          setData({
            step: 3,
            ...newAccount,
            ...data,
            hostedPageId,
          });
        },
        close: () => {
          setStatus('initial');
        },
        step() {
          setStatus('loading');
        },
        error(err) {
          if (err.response.status === 422) {
            if (err.response.data?.chargebee || err.response.data?.body?.chargebee) {
              // set the error message
              setErrors({ chargebee: err.response.data?.chargebee?.message || err.response.data?.body?.chargebee });
            } else {
              setErrors(flattenValidation(err.response.data.body));
            }
          } else if (err.response.status >= 400) {
            setErrors({ general: consolidateErrors(err.response) });
          } else {
            logger.error(err.response?.data);
          }

          instance.closeAll();
          setStatus('initial');
        },
      });

      tracker.push({
        event: 'atomic_signup_new_account_created',
        ...breadcrumbMeta,
      });
    } catch (err) {
      setStatus('initial');
    }
  };

  const onSetField = (key, value) => {
    setData({
      [key]: value,
    });
  };

  const setErrors = (err) => {
    setData({
      errors: {
        ...signup.errors,
        ...err,
      },
    });
  };

  const isFormFilled = [
    signup.plan?.id,
    signup.firstName,
    signup.lastName,
    signup.password,
  ].every(Boolean);

  return (
    <SignupWrapper title="Account Information">
      <Box as={Paper} padding="medium" direction="column" gap="small">
        <Box direction="row" flex={1} gap="large" justify="space-between" align="flex-start">
          <Box direction="column" gap="medium" flex={1} css={{ maxWidth: 720 }}>
            <Box direction="column" wrap="wrap" gap="small">
              <AccountInfo
                signup={signup}
                onChange={onSetField}
                setErrors={setErrors}
              />
            </Box>
            {
              signup.errors.general && (
                <Typography color="error">{signup?.errors?.general}</Typography>
              )
            }
            {
              signup.errors.email && (
                <Typography color="error">{signup?.errors?.email}</Typography>
              )
            }
          </Box>
          <Box
            padding="medium"
            css={totalCardContainer}
          >
            <TotalColumn includeTotal />
          </Box>
        </Box>

        <Box direction="row" justify="space-between" flex={1} align="flex-end">
          <div>
            <Button
              onClick={() => { setData({ step: 1 }); }}
              color="default"
              variant="outlined"
            >
              Back
            </Button>
          </div>
          <Box direction="column" align="flex-end">
            <div>
              <Button
                data-cy="confirm-continue"
                color="secondary"
                onClick={onSubmit}
                status={status}
                disabled={!isFormFilled}
                variant="contained"
              >
                Sign Up
              </Button>
            </div>
          </Box>
        </Box>
      </Box>
    </SignupWrapper>
  );
};

AccountSetup.propTypes = {
  requestHostedPage: func.isRequired,
  setData: func.isRequired,
  signup: object.isRequired,
};

export default AccountSetup;
