import makeApiAction from 'shared/utils/redux/apiActions';

export const SIGNUP_SET_DATA = 'SIGNUP_SET_DATA';

export function setData(data) {
  return {
    type: SIGNUP_SET_DATA,
    data,
  };
}

export const requestHostedPage = (options) => makeApiAction(
  ({ api }) => {
    const {
      firstName,
      lastName,
      email,
      password,
      companyName,
      billingPlanId,
      region,
      addons,
      tos,
      cycle,
      billingOptions,
    } = options;

    return api({ timeout: 40000 }).post('go-billing/accounts/hosted_pages', {
      firstName,
      lastName,
      email,
      companyName,
      password,
      planId: `${billingPlanId}`,
      regionId: region,
      addons,
      billingSource: 'chargebee',
      billingFrequency: cycle,
      acceptTos: tos,
      ...billingOptions,
    });
  },
  'SIGNUP_REQUEST_HOSTED_PAGE_R',
  'SIGNUP_REQUEST_HOSTED_PAGE_S',
  'SIGNUP_REQUEST_HOSTED_PAGE_F',
  null,
  true,
);

export const getHostedPage = (pageId) => makeApiAction(
  ({ api }) => {
    return api().get(`go-billing/accounts/hosted_pages/${pageId}`);
  },
  'SIGNUP_GET_HOSTED_PAGE_R',
  'SIGNUP_GET_HOSTED_PAGE_S',
  'SIGNUP_GET_HOSTED_PAGE_F',
  { pageId },
  true,
);

export const requestPayPalToken = (signupConfig) => makeApiAction(
  ({ api }) => {
    return api().post('billing/paypal/token', signupConfig);
  },
  'SIGNUP_PAYPAL_TOKEN_R',
  'SIGNUP_PAYPAL_TOKEN_S',
  'SIGNUP_PAYPAL_TOKEN_F',
  null,
  true,
);

export const requestPayPalAgreement = (signupConfig) => makeApiAction(
  ({ api }) => {
    return api().post('billing/paypal/billing-agreement', signupConfig);
  },
  'SIGNUP_PAYPAL_AGREEMENT_R',
  'SIGNUP_PAYPAL_AGREEMENT_S',
  'SIGNUP_PAYPAL_AGREEMENT_F',
  null,
  true,
);
