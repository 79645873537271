/* eslint-disable */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { StylesProvider } from '@material-ui/core/styles';

import { func, object, shape } from 'prop-types';
import { getCatalog } from 'shared/modules/billing/redux/actions';
import { setData, requestHostedPage, getHostedPage } from 'customer/store/actions';
import { SUCCESS } from 'shared/utils/redux/constants';
import {
  createAccount, updateAccount,
  addCollaborator,
  pollAccount,
  fetchAccountOnly,
} from 'shared/modules/account/redux/actions';
import { requestAccountLogin } from 'shared/modules/authentication/redux/actions';
import { accountPropTypes } from 'shared/modules/account/models/model';
import promise from 'shared/utils/promiseUtils';

import ErrorBoundary from 'shared/modules/webapp/components/ErrorBoundary';
import ChoosePlan from './Steps/ChoosePlan';
import ChooseAddons from './Steps/ChooseAddons';
import AccountSetup from './Steps/AccountSetup';
import AccountCreating from './Steps/AccountCreating';
import ThankYou from './PostSignup/ThankYou';
import SetPin from './PostSignup/SetPin';
import TwoFactor from './PostSignup/2FA';
import Articles from './PostSignup/Articles';
import Final from './PostSignup/Final';
import InviteTeam from './PostSignup/InviteTeam';
import AddSSHKeys from './PostSignup/AddSSHKeys';

// this one takes care of rendering the correct step

export const SignupHandler = ({
  getCatalog, plans, setData, signup,
  requestHostedPage,
  addCollaborator,
  requestAccountLogin,
  updateAccount,
  pollAccount,
  getHostedPage,
  fetchAccount,
  account,
}) => {
  useEffect(() => {
    getCatalog();
  }, []);

  const signupProps = {
    signup,
    setData,
  };

  const memoizedContent = [
    (<ChoosePlan
      key="choose-plan"
      plans={plans}
      {...signupProps}
    />),
    (<ChooseAddons
      key="choose-addons"
      plans={plans}
      {...signupProps}
    />),
    (<AccountSetup
      key="account-info"
      {...signupProps}
      requestHostedPage={requestHostedPage}
    />),
    (<AccountCreating
      key="creating"
      {...signupProps}
      getHostedPage={getHostedPage}
      fetchAccount={fetchAccount}
      updateAccount={updateAccount}
      requestAccountLogin={requestAccountLogin}
      fetchPollAccount={pollAccount}
    />),
    (<ThankYou
      key="thank-you"
      {...signupProps}
    />),
    (<SetPin key="set-pin" {...signupProps} updateAccount={updateAccount} />),
    (<TwoFactor key="mfa" {...signupProps} account={account} />),
    (<InviteTeam key="team" {...signupProps} addCollaborator={addCollaborator} />),
    (<AddSSHKeys key="add-keys" {...signupProps} />),
    (<Articles key="articles" {...signupProps} />),
    (<Final key="final" {...signupProps} />),
  ];

  return (
    <ErrorBoundary>
      {
        plans.status === SUCCESS
        && memoizedContent[signup.step]
      }
    </ErrorBoundary>
  );
};

SignupHandler.propTypes = {
  account: shape(accountPropTypes),
  addCollaborator: func.isRequired,
  createAccount: func.isRequired,
  fetchAccount: func.isRequired,
  getCatalog: func.isRequired,
  getHostedPage: func.isRequired,
  plans: object,
  pollAccount: func.isRequired,
  requestAccountLogin: func.isRequired,
  setData: func.isRequired,
  signup: object,
  updateAccount: func.isRequired,
};

export default connect(
  (state) => ({
    plans: state.billing.plans,
    signup: state.signup,
    account: state.account,
  }), {
    getCatalog,
    setData,
    pollAccount,
    fetchAccount: fetchAccountOnly,
    requestAccountLogin,
    updateAccount,
    addCollaborator,
    requestHostedPage,
    getHostedPage,
  },
)(SignupHandler);
