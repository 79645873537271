import React from 'react';
import {
  bool, func, object, string,
} from 'prop-types';
import { connect, useSelector } from 'react-redux';
import {
  Divider, Typography, Switch,
} from '@material-ui/core';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { setData } from 'customer/store/actions';

import Box from 'shared/styleguide/atoms/Box';
import { localizeCurrency } from 'shared/utils';
import { totalCard, totalCardLeft } from './styles';
import { truncateCurrencyOptions } from '../utils';

export const GetTotalAndDiscount = () => {
  const signup = useSelector((state) => state.signup);
  const catalog = useSelector((state) => state.billing.plans);

  const { plan, cycle, region } = signup;

  let total = 0;
  if (cycle === 'monthly') {
    total += plan.monthlyPrice.price;
    total += (region.monthlyPrice.price * plan.nsu) ?? 0;
  } else {
    total += plan.yearlyPrice.price;
    total += (region.yearlyPrice.price * plan.nsu) ?? 0;
  }

  const undiscountedTotal = total;
  const discount = Object.values(catalog?.data?.coupons).find((coupon) => coupon.appliesTo === cycle);
  const totalOff = undiscountedTotal * ((discount?.value || 0) / 100);
  const netTotal = (total - totalOff) / 100;

  return {
    undiscountedTotal,
    totalOff,
    netTotal,
  };
};

const StyledToggle = styled(Switch)(
  ({ theme }) => css`
  span {
    color: ${theme.palette.secondary.main} !important;
  }
  `,
);

export const InlineToggle = ({ signup, align, setData: setDataAction }) => {
  const { cycle } = signup;

  const toggleCycle = () => {
    setDataAction({
      cycle: cycle === 'yearly' ? 'monthly' : 'yearly',
    });
  };

  return (
    <Box align={align || 'left'}>
      <Box direction="row" align="center">
        <Typography variant="body2">Monthly</Typography>
        <StyledToggle
          role="switch"
          checked={(cycle === 'yearly')}
          onChange={toggleCycle}
          color="secondary"
        />
        <Typography variant="body2">Annual</Typography>
      </Box>
      <Typography variant="caption" color="textSecondary" gutterBottom>Save 5% with a yearly plan</Typography>
    </Box>
  );
};

InlineToggle.propTypes = {
  align: string,
  includeTotal: bool,
  setData: func.isRequired,
  signup: object.isRequired,
};

const Plan = ({
  signup, includeTotal,
}) => {
  const { plan, cycle } = signup;

  return (
    <Box css={[totalCard, includeTotal && totalCardLeft]}>
      <Typography variant="h2">{plan.name}</Typography>
      <Box padding={{ top: 'xsmall', bottom: 'xsmall' }} css={{ width: '100%' }}>
        <Divider />
      </Box>
      <Typography variant="h1">
        <Typography component="span" variant="body2" css={{ verticalAlign: 'super' }}>$</Typography>
        {localizeCurrency((cycle === 'monthly' ? plan.monthlyPrice.price : plan.yearlyPrice.price) / 100, truncateCurrencyOptions)}
      </Typography>
      <Typography variant="caption">USD {cycle === 'monthly' ? 'per month' : 'annually'}</Typography>
      <Box padding={{ top: 'medium', bottom: 'small' }} css={{ width: '100%' }}>
        <Divider />
      </Box>
      <Box>
        {
          Object.values(plan.addons).map((addon) => (
            <Typography
              variant="body2"
              color="textSecondary"
              css={{ lineHeight: '1.5' }}
              key={addon.name}
            >
              {[
                (addon.hideQuantity ? '' : addon.quantity),
                addon?.unit?.includes('iB') ? addon.unit : null,
                addon.name,
                addon?.unit?.includes('Units') ? addon.unit : null,
              ].join(' ').trim()}
            </Typography>
          ))
        }
      </Box>
    </Box>
  );
};

Plan.propTypes = {
  catalog: object.isRequired,
  includeTotal: bool,
  setData: func,
  signup: object.isRequired,
};

const Region = ({ signup, includeTotal }) => {
  const { region, cycle, plan } = signup;

  return (
    <Box css={[totalCard, includeTotal && totalCardLeft]} padding={{ top: 'small' }}>
      <Typography component="span" variant="h2">
        +&nbsp;
        <Typography component="span" variant="h1">
          <Typography component="span" variant="body2" css={{ verticalAlign: 'super' }}>$</Typography>
          {localizeCurrency((region[`${cycle}Price`].price * (plan.nsu || 1)) / 100, truncateCurrencyOptions)}
        </Typography>
      </Typography>
      <Typography variant="caption" gutterBottom>
        USD {cycle === 'monthly' ? 'per month' : 'annually'}
      </Typography>
      <Typography variant="h6" color="textSecondary">
        Tier {region.tier} Hosting Region
      </Typography>
      <Typography variant="body1">{region.name}</Typography>
    </Box>
  );
};

Region.propTypes = {
  includeTotal: bool,
  setData: func,
  signup: object.isRequired,
};

const TotalCard = (props) => {
  const { includeTotal, catalog, toggleOnly } = props;
  const { plan, cycle, region } = props.signup;

  if (toggleOnly) {
    return <InlineToggle {...props} />;
  }

  if (!plan) {
    return null;
  }

  let total = 0;
  if (cycle === 'monthly') {
    total += plan.monthlyPrice.price;
    total += (region.monthlyPrice.price * plan.nsu) ?? 0;
  } else {
    total += plan.yearlyPrice.price;
    total += (region.yearlyPrice.price * plan.nsu) ?? 0;
  }

  const undiscountedTotal = total;
  const discount = Object.values(catalog?.data?.coupons).find((coupon) => coupon.appliesTo === cycle);
  const totalOff = undiscountedTotal * ((discount?.value || 0) / 100);

  if (!includeTotal) {
    return (
      <Box>
        <Plan {...props} />
        {
          (cycle !== 'monthly' && discount) && (
            <Box css={[totalCard, includeTotal && totalCardLeft]} padding={{ top: 'medium' }}>
              <Typography component="span" variant="subtitle1" gutterBottom>
                -&nbsp;
                <Typography component="span" variant="h5">
                  <Typography component="span" variant="body2" css={{ verticalAlign: 'super' }}>$</Typography>
                  {localizeCurrency(totalOff / 100, truncateCurrencyOptions)}
                </Typography>
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                {discount.name}
              </Typography>
            </Box>
          )
        }
        <Box margin={{ top: 'small' }}>
          <InlineToggle {...props} />
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Plan {...props} />
      {
        region.id && (
          <Region {...props} />
        )
      }
      {
        (cycle !== 'monthly' && discount) && (
          <Box css={[totalCard, includeTotal && totalCardLeft]} padding={{ top: 'small' }}>
            <Typography component="span" variant="h2">
              -&nbsp;
              <Typography component="span" variant="h1">
                <Typography component="span" variant="body2" css={{ verticalAlign: 'super' }}>$</Typography>
                {localizeCurrency(totalOff / 100, truncateCurrencyOptions)}
              </Typography>
            </Typography>
            <Typography variant="h6" color="textSecondary">
              {discount.name}
            </Typography>
          </Box>
        )
      }
      <Box padding={{ top: 'medium', bottom: 'xxsmall' }} css={{ width: '100%' }}>
        <Divider />
      </Box>
      <Box align="flex-end" direction="row" justify="space-between">
        <Typography component="span" variant="h2">=</Typography>
        <Typography component="span" variant="h1">
          <Typography component="span" variant="body2" css={{ verticalAlign: 'super' }}>$</Typography>
          {localizeCurrency((total - totalOff) / 100, truncateCurrencyOptions)}
        </Typography>
      </Box>
      <Typography variant="caption" align="right">
        USD {cycle === 'monthly' ? 'per month' : 'annually'}
      </Typography>
      <Box margin={{ top: 'small' }}>
        <InlineToggle {...props} />
      </Box>
    </Box>
  );
};

TotalCard.propTypes = {
  catalog: object.isRequired,
  includeTotal: bool,
  signup: object.isRequired,
  toggleOnly: bool,
};

export default connect(
  (state) => ({
    signup: state.signup,
    catalog: state.billing.plans,
  }),
  {
    setData,
  },
)(TotalCard);
